import { useEffect, useState } from 'react'

export const useTimer = (
  minutes: number,
  seconds: number,
  ref?: React.RefObject<HTMLSpanElement>
) => {
  const initialMinutes = minutes
  const initialSeconds = seconds
  let timeOutHandler: NodeJS.Timeout | null = null
  const [isCompleted, setIsCompleted] = useState(false)
  const [counterContainer, setCounterContainer] = useState(ref?.current)

  const countDown = (minutes: number, seconds: number) => {
    const tick = () => {
      if (!counterContainer) return
      counterContainer.textContent =
        minutes + ':' + (seconds < 10 ? '0' : '') + seconds.toString()
      seconds--
      if (timeOutHandler) clearTimeout(timeOutHandler)
      if (seconds >= 0) {
        timeOutHandler = setTimeout(tick, 1000)
      } else {
        if (minutes >= 1) {
          timeOutHandler = setTimeout(tick, 1000)
          seconds = 59
          minutes--
        }
      }

      if (minutes === 0 && seconds === 0) {
        setTimeout(() => setIsCompleted(true), 1000)
      }
    }
    tick()
  }

  const startCountDown = () => {
    setIsCompleted(false)
    countDown(minutes, seconds)
  }

  const resetCountDown = () => {
    setIsCompleted(false)
    countDown(initialMinutes, initialSeconds)
  }

  useEffect(() => {
    if (!counterContainer) return
    startCountDown()
  }, [counterContainer])

  useEffect(() => {
    setCounterContainer(ref?.current)
  }, [ref])

  return {
    resetCountDown,
    isCompleted,
  }
}
