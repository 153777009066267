import * as Stitches from '@stitches/react'

import { keyframes, styled } from '@/styles/stitches.config'

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
})

export const Spinner = styled('svg', {
  border: '5px solid $gray',
  borderTop: `5px solid $selection`,
  borderRadius: `50%`,
  animation: `${spin} 1s linear infinite`,

  variants: {
    size: {
      xs: {
        width: `16px`,
        height: `16px`,
      },
      sm: {
        width: `20px`,
        height: `20px`,
      },
      md: {
        width: `40px`,
        height: `40px`,
      },
      lg: {
        width: `60px`,
        height: `60px`,
      },
    },
  },

  defaultVariants: {
    size: 'sm',
  },
})

export type SpinnerVariants = Stitches.VariantProps<typeof Spinner>
