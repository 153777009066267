import { useSuspenseQuery } from '@tanstack/react-query'
import { ChangeEvent, FC, useRef, useState } from 'react'

import { Button } from '@/components/button'
import { Input } from '@/components/input'
import { Box } from '@/components/layout'
import { Loader } from '@/components/loader'
import { Description, Title, Typography } from '@/components/typography'
import { useTimer } from '@/hooks/useTimer'
import { resetPasswordOTPQueryOptions } from '@/queries/reset-password'

interface Props {
  setStep: (step: number) => void
  setOTP: (code: string) => void
  track_id: string
}

export const RequestPasswordCodeVerification: FC<Props> = ({
  setStep,
  setOTP,
  track_id,
}) => {
  const counterRef = useRef<HTMLSpanElement>(null)
  const [code, setCode] = useState('')
  const { isCompleted, resetCountDown } = useTimer(1, 0, counterRef)
  const sendResetPasswordOtpCode = useSuspenseQuery(
    resetPasswordOTPQueryOptions(track_id)
  )

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (!value) return setCode('')
    setCode(value)
  }

  const resendCode = () => {
    sendResetPasswordOtpCode.refetch()
    resetCountDown()
  }

  const proceed = () => {
    setOTP(code)
    setStep(1)
  }

  return (
    <Box direction="column" align="start">
      <Title>Enter the code we texted you.</Title>
      <Description size="sm" css={{ mt: '$16', mb: '$40' }}>
        Please enter the code texted to your phone number.
      </Description>
      {sendResetPasswordOtpCode.isPending ? (
        <Loader />
      ) : (
        <Box direction="column" align="start">
          <Input
            name="confirmationCode"
            type="text"
            placeholder="Enter Code"
            onChange={handleCodeChange}
            disabled={sendResetPasswordOtpCode.isPending}
          />
          <Typography size="sm" color="muted">
            Standard messaging rates may apply.
          </Typography>
        </Box>
      )}
      <Box direction="column" align="start" css={{ mt: '$24' }}>
        <Typography size="sm" color="muted">
          Problems receiving code?
        </Typography>
        <Typography
          bold
          color="muted"
          size="sm"
          css={{
            display: `${isCompleted ? 'none' : 'block'}`,
            mt: '$4',
            mb: '$8',
          }}
        >
          Resend Code in <span ref={counterRef}></span>
        </Typography>
        {isCompleted && (
          <Button mode="link" onClick={resendCode}>
            Resend Code
          </Button>
        )}
      </Box>
      <Button css={{ mt: '$24' }} onClick={proceed} disabled={!code}>
        Enter Code
      </Button>
    </Box>
  )
}
