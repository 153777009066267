import {
  Box,
  Button,
  Dialog,
  DialogDescription,
  DialogTitle,
  Icon,
  Typography,
} from '@branch-messenger/willow-ui'
import {
  ChevronRight,
  Mail01,
  MessageChatCircle,
} from '@branch-messenger/willow-ui/icons'
import { FC, useEffect, useState } from 'react'

import { NewOptionItem } from '@/components/options'
import { useSimpleTimer } from '@/hooks/useSimpleTimer'
import { sendAnalyticsEvent } from '@/lib/mixpanel'
import { VerificationChannel } from '@/types/auth'

interface Props {
  resendCode: (channel: VerificationChannel) => void
  setVerificationChannel: (channel: VerificationChannel) => void
  phoneLastFourDigits: string
  verificationChannel: VerificationChannel
  email?: string
}

export const VerificationCodeGetHelp: FC<Props> = ({
  resendCode,
  setVerificationChannel,
  phoneLastFourDigits,
  verificationChannel,
  email,
}) => {
  const [open, setOpen] = useState(false)
  const timer = useSimpleTimer(1, 0)

  useEffect(() => {
    sendAnalyticsEvent('ONBOARDING', 'verification-code:get-help')
  }, [])

  const handleSendToPhone = () => {
    setVerificationChannel('PHONE')
    resendCode('PHONE')
    timer.resetCountDown()
    setOpen(false)
  }

  const handleSendToEmail = () => {
    setVerificationChannel('EMAIL')
    resendCode('EMAIL')
    setOpen(false)
  }

  const method = verificationChannel === 'PHONE' ? 'text' : 'email'

  const description =
    verificationChannel === 'PHONE' ? (
      <>
        {`We're trying to verify it's you using the phone number ending in `}
        <strong>{phoneLastFourDigits}</strong>.
      </>
    ) : (
      <>
        {`We're trying to verify it's you using the email `}
        <strong>{email}</strong>.
      </>
    )

  return (
    <Box $mt={4}>
      <Typography $size="sm" $color="textMuted">
        Problems receiving {method}?
      </Typography>
      <Dialog
        trigger={
          <Button
            variant="link"
            color="secondary"
            iconRight={<Icon Icon={ChevronRight} />}
          >
            Get Help
          </Button>
        }
        size="sm"
        open={open}
        onOpenChange={setOpen}
      >
        <DialogTitle>Verification code</DialogTitle>
        <Box $mb={4}>
          <DialogDescription>{description}</DialogDescription>
        </Box>
        <Box $direction="column" $display="flex" $gap={8} $mt={4}>
          <NewOptionItem
            onClick={handleSendToPhone}
            icon={MessageChatCircle}
            disabled={!timer.isCompleted}
          >
            {timer.isCompleted
              ? 'Send code to phone'
              : `Resend text in ${timer.currentMinutes}:${timer.currentSeconds}`}
          </NewOptionItem>
          <NewOptionItem
            onClick={handleSendToEmail}
            icon={Mail01}
            disabled={false}
          >
            Send Code to Email
          </NewOptionItem>
        </Box>
      </Dialog>
    </Box>
  )
}
