import {
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useContext } from 'react'

import {
  getWorker,
  login,
  loginWorker,
  logout,
  resendOtp,
  sendEmailVerification,
  verifyLogin,
} from '@/api/auth'
import { DialogContext } from '@/context'
import { useAuth } from '@/context/Auth'
import { useToastContext } from '@/context/ToastContext'
import { handleHttpException } from '@/lib/httpException'
import {
  clearMixpanelUser,
  identifyUser,
  sendAnalyticsEvent,
  setUserProperties,
} from '@/lib/mixpanel'
import { queryClient } from '@/lib/query-client'
import { cleanUpCookieandStorage } from '@/lib/token'
import { LoginRequestBody, ResendOTP, VerifyLoginOTP } from '@/types/auth'
import { OldIResponse } from '@/types/common'

import { GET_DISBURSEMENTS_QUERY } from './disbursements'
import { ORGANIZATION_INFO_QUERY } from './organization'
import { GET_PAYMENT_PROFILE_QUERY } from './profile'

export const useSendEmailVerificationMutation = () => {
  return useMutation({
    mutationFn: async (email: string) => await sendEmailVerification(email),
  })
}

export const useLogin = (handleEmailVerificationError?: () => void) => {
  const navigate = useNavigate()
  const { showAlert } = useContext(DialogContext)
  const handleLoginError = () => {
    showAlert({
      title: 'Unable To Login',
      description: 'Please check your information and try again.',
      submitText: 'OK',
      handleOnConfirm: () => null,
      hideCancel: true,
    })
  }
  return useMutation({
    mutationFn: async (data: LoginRequestBody) => {
      const res = await login(data)
      return res.data
    },

    onSuccess: () => sendAnalyticsEvent('AUTH', 'login:success'),

    onError: (error, payload) => {
      sendAnalyticsEvent('AUTH', 'login:failure')
      handleHttpException(error, {
        onHttpError: error => {
          if (error.response?.status === 412) {
            if (handleEmailVerificationError) {
              return handleEmailVerificationError()
            }
            navigate({
              to: '/setup/email-verification/$email',
              params: { email: payload.email },
              state: { loginRequestBody: payload },
            })
          } else {
            handleLoginError()
          }
        },
        onOtherError: handleLoginError,
      })
    },
  })
}

export const useResendCode = () => {
  const { showToast } = useToastContext()
  return useMutation({
    mutationFn: async (data: ResendOTP) => {
      const res = await resendOtp(data)
      return res.data
    },
    onSuccess: (_, { verification_channel }) => {
      const method = verification_channel === 'PHONE' ? 'Text' : 'Email'
      showToast({
        title: `${method} successfully sent`,
        type: 'success',
      })
    },
    onError: () => {
      showToast({
        title: `Error sending code`,
        type: 'alert',
      })
    },
  })
}

export const useVerifyCode = () => {
  const { showAlert } = useContext(DialogContext)

  return useMutation({
    mutationFn: async (data: VerifyLoginOTP) => {
      const res = await verifyLogin(data)
      return res.data
    },

    onSuccess: (_, payload) => {
      setUserProperties({ email: payload?.email })
    },

    onError: () => {
      showAlert({
        title: 'Unable To Login',
        description: 'Please check your information and try again.',
        submitText: 'OK',
        handleOnConfirm: () => null,
        hideCancel: true,
      })
    },
  })
}

export const useLogout = () => {
  const queryClient = useQueryClient()
  const { showAlert } = useContext(DialogContext)
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async () => {
      const res = await logout()
      clearMixpanelUser()
      return res.data
    },

    onSuccess: () => {
      cleanUpCookieandStorage()
      queryClient.invalidateQueries({ queryKey: [ORGANIZATION_INFO_QUERY] })
      navigate({ to: '/login' })
    },

    onError: () => {
      showAlert({
        title: 'Unable To Logout',
        description: 'Please try again.',
        submitText: 'OK',
        handleOnConfirm: () => null,
        hideCancel: true,
      })
    },
  })
}

export const useLoginUserMutation = () => {
  const { setOrgId } = useAuth()
  const navigate = useNavigate()
  const { showAlert } = useContext(DialogContext)
  return useMutation({
    mutationFn: async () => {
      const workerInfo = await queryClient.ensureQueryData(
        workerInfoQueryOptions
      )
      const { employee_id, org_id } = workerInfo[0]
      identifyUser(`${org_id}-${employee_id}`)
      await loginWorker(workerInfo[0])
      return workerInfo
    },
    onSuccess: workerInfo => {
      const orgId = workerInfo[0].org_uuid as string
      setOrgId(orgId)
      navigate({ to: '/account' })
    },
    onError: () => {
      showAlert({
        title: 'Unable To Login',
        description: 'Please check your information and try again.',
        submitText: 'OK',
        handleOnConfirm: () => {
          cleanUpCookieandStorage()
          navigate({ to: '/login' })
        },
        hideCancel: true,
      })
    },
  })
}

export const GET_WORKER_INFO_QUERY = 'get-worker-info-query'

export const workerInfoQueryOptions = queryOptions({
  queryKey: [GET_WORKER_INFO_QUERY],
  queryFn: async () => {
    const { data } = await getWorker()
    return data
  },
})

export const useSwitchOrganization = () => {
  const { setOrgId } = useAuth()
  const { showAlert } = useContext(DialogContext)
  const { data: workerOrganizations } = useSuspenseQuery(workerInfoQueryOptions)
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (orgUuid: string) => {
      const { employee_id, org_id } =
        workerOrganizations.find(org => org.org_uuid === orgUuid) || {}
      if (!employee_id || !org_id) {
        throw new Error('Invalid orgId')
      }
      return loginWorker({
        employee_id,
        org_id,
      })
    },
    onSuccess: (_, orgUuid) => {
      queryClient.resetQueries({
        predicate: query => {
          const queryKey = query.queryKey[0]
          return (
            queryKey === GET_DISBURSEMENTS_QUERY ||
            queryKey === GET_PAYMENT_PROFILE_QUERY
          )
        },
      })
      setOrgId(orgUuid)
    },
    onError: error => {
      handleHttpException<OldIResponse>(error, {
        onHttpError: error => {
          showAlert({
            title: 'Unable To Switch Organization',
            description: error.response?.data?.message,
            submitText: 'OK',
            handleOnConfirm: () => null,
            hideCancel: true,
          })
        },
        onOtherError: () => {
          showAlert({
            title: 'Unable To Switch Organization',
            description:
              'An error occurred switching organizations please try again later.',
            submitText: 'OK',
            handleOnConfirm: () => null,
            hideCancel: true,
          })
        },
      })
    },
  })
}
