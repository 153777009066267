import * as Primitive from '@radix-ui/react-progress'

import { styled } from '@/styles/stitches.config'

export const ProgressRoot = styled(Primitive.Root, {
  position: 'relative',
  overflow: 'hidden',
  background: '$background',
  borderRadius: '99999px',
  width: '100%',
  height: '$8',

  /* Fix overflow clipping in Safari */
  /* https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0 */
  transform: 'translateZ(0)',
})

export const ProgressIndicator = styled(Primitive.Indicator, {
  backgroundColor: '$successBackground',
  width: '100%',
  height: '100%',
  transition: 'transform 660ms cubic-bezier(0.65, 0, 0.35, 1)',
})
