import { FC, useMemo, useState } from 'react'

import { Box } from '@/components/layout'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Title, Typography } from '@/components/typography'
import { centsToDollar } from '@/lib/currency'
import { formatISODateToMonDDYYYY } from '@/lib/date-helpers'
import { formatAccountNumberLast4 } from '@/lib/string-formatting'
import { useGetDisbursementDetails } from '@/queries/disbursements'
import {
  Disbursement,
  DisbursementDetailsDirect,
  DisbursementDetailsWallet,
  DisbursementPaymentType,
} from '@/types/disbursements'

type Props = Omit<Disbursement['payout'], 'ref'> & {
  id: number
  description: string
  detailsRef: Disbursement['payout']['ref']
  payoutTotalAmountRequested: number
}

export const Payout: FC<Props> = ({
  id,
  payment_type,
  amount,
  description,
  fee,
  time_completed,
  detailsRef,
  payoutTotalAmountRequested,
}) => {
  const [open, setOpen] = useState(false)

  const disbursementDetails = useGetDisbursementDetails(detailsRef, id)

  const last4 = useMemo(() => {
    if (!disbursementDetails.data) return ''
    if (payment_type === DisbursementPaymentType.WALLET) {
      const accountNumber = (
        disbursementDetails.data as DisbursementDetailsWallet
      ).account_number
      return formatAccountNumberLast4(accountNumber)
    } else if (payment_type === DisbursementPaymentType.DIRECT) {
      return (disbursementDetails.data as DisbursementDetailsDirect).last_four
    }
  }, [disbursementDetails.data, payment_type])

  const toggleOpen = () => {
    if (!last4) disbursementDetails.refetch()
    setOpen(!open)
  }

  return (
    <Box direction="column" css={{ padding: '$16', width: '100%' }}>
      <Box css={{ cursor: 'pointer' }} onClick={toggleOpen}>
        <Box direction="column" align="start">
          <Title size="sm">{formatISODateToMonDDYYYY(time_completed)}</Title>
          <Description css={{ mt: '$4' }} size="xs">
            {payment_type}
          </Description>
          <Description css={{ mt: '$4' }} color="muted" size="xs">
            {description}
          </Description>
        </Box>
        <Box fullWidth align="center">
          <Typography bold>{centsToDollar(amount)}</Typography>
          <SVGIcon
            wrapperStyle={{ width: '$24', marginLeft: '$8' }}
            iconName={open ? 'arrow_up' : 'arrow_down'}
          />
        </Box>
      </Box>
      {open && (
        <Box direction="column">
          <Box justify="between">
            <Description size="xs">Payout Amount</Description>
            <Description size="xs">
              {centsToDollar(payoutTotalAmountRequested)}
            </Description>
          </Box>
          <Box justify="between">
            <Description size="xs">Fee</Description>
            <Description size="xs">– {centsToDollar(fee)}</Description>
          </Box>
          <Box css={{ borderBottom: '1px solid $gray', mt: '$16' }} />
          <Box justify="between" css={{ my: '$16', cursor: 'pointer' }}>
            <Box direction="column" align="start">
              <Title size="sm">Total Amount</Title>
              <Description css={{ mt: '$4' }} size="xs">
                Paid to {payment_type} ••• {last4}
              </Description>
            </Box>
            <Description size="xs">{centsToDollar(amount)}</Description>
          </Box>
        </Box>
      )}
    </Box>
  )
}
