import { useNavigate } from '@tanstack/react-router'
import { FC } from 'react'

import { Button } from '@/components/button'
import { Box, Flex } from '@/components/layout'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Title } from '@/components/typography'
import { useOrg } from '@/selectors/useOrg'

export const InstantPayDisabled: FC = () => {
  const navigate = useNavigate()
  const { orgInfo } = useOrg()
  return (
    <Box direction="column">
      <Flex justify="center" css={{ mb: '$24' }}>
        <SVGIcon iconName="green_check" />
      </Flex>
      <Box direction="column" align="start">
        <Title>Instant payouts have been turned off.</Title>
        <Description>
          Remember to check your {orgInfo?.name} HR account and make sure you
          have a payouts method selected.
        </Description>
        <Description>
          {`If you'd like to enable instant payouts again, select a payment type
          from the account management screen.`}
        </Description>
      </Box>
      <Box css={{ mt: '$24', mr: 'auto' }}>
        <Button onClick={() => navigate({ to: '/account' })}>Got It</Button>
      </Box>
    </Box>
  )
}
