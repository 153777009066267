import { Box, Typography } from '@branch-messenger/willow-ui'

import { PageLayoutContent } from '../page-layout/PageLayoutContent'
import { PageLayoutFooter } from '../page-layout/PageLayoutFooter'
import { PageLayoutHeader } from '../page-layout/PageLayoutHeader'
import { StyledPageLayoutContainer } from '../page-layout/styles'

export const GenericError = () => (
  <StyledPageLayoutContainer>
    <PageLayoutHeader />
    <PageLayoutContent>
      <Box $mt={4} $mb={5}>
        <Typography $size="sm">
          An error occured. Please try again or contact Branch support{' '}
          <a
            target="_blank"
            href="https://support.branchapp.com/hc/en-us/requests/new"
            rel="noreferrer"
          >
            here
          </a>
        </Typography>
      </Box>
    </PageLayoutContent>
    <PageLayoutFooter />
  </StyledPageLayoutContainer>
)
