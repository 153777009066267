import { useNavigate, useSearch } from '@tanstack/react-router'
import { useEffect } from 'react'

import { Button } from '@/components/button'
import { Box } from '@/components/layout'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Title, Typography } from '@/components/typography'
import { useAuth } from '@/context/Auth'

export const Landing = () => {
  const { org_id } = useSearch({ from: '/' })
  const { setOrgId } = useAuth()

  const navigate = useNavigate()
  const login = () => {
    navigate({ to: '/login' })
  }

  useEffect(() => {
    if (org_id) {
      setOrgId(org_id)
      navigate({ to: '/setup' })
    }
  }, [navigate, org_id, setOrgId])

  return (
    <Box direction="column" align="start">
      <Box justify="center" align="center">
        <SVGIcon iconName="missing_org" wrapperStyle={{ width: 'auto' }} />
      </Box>
      <Title>Looking to set up payments through Branch?</Title>
      <Description size="sm" css={{ mt: '$16' }}>
        {`Use the URL provided by your organization. If you can't find it, please
        reach out to them.`}
      </Description>
      <Typography size="sm" color="muted" css={{ mt: '$24' }}>
        Already have an account?
      </Typography>
      <Button mode="link" css={{ mt: '$4' }} onClick={login}>
        Log In {`>`}
      </Button>
    </Box>
  )
}
