import { theme as willowTheme } from '@branch-messenger/willow-ui'
import { createStitches } from '@stitches/react'

import { fontWeights } from './fonts'
import { media } from './media'
import { radii } from './radii'
import { spacing } from './spacing'
import { utils } from './utils'

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: willowTheme.colors,
    shadows: willowTheme.shadows,
    space: spacing,
    sizes: spacing,
    fontSizes: spacing,
    lineHeights: spacing,
    fontWeights,
    radii,
  },
  media,
  utils,
})

export const globalStyles = globalCss({
  html: {
    overflowY: 'scroll',
  },
  '*': {
    fontFamily: "'Reddit Sans Variable', sans-serif",
    boxSizing: 'border-box',
  },
  '*::-webkit-scrollbar': {
    backgroundColor: 'transparent',
  },
  '*::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '*::-webkit-scrollbar-thumb': {
    borderRadius: '$xl',
    border: '2px solid transparent',
    backgroundColor: 'rgba(0,0,0,0.3)',
    backgroundClip: 'content-box',
  },
  body: {
    margin: 0,
  },
})
