import { useSuspenseInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { IconButton } from '@/components/button'
import { Box } from '@/components/layout'
import { Loader } from '@/components/loader'
import { Title } from '@/components/typography'
import { disbursementsQueryOptions } from '@/queries/disbursements'

import { NoPayouts } from './NoPayouts'
import { Payout } from './Payout'
import { PayoutErrorMessage } from './PayoutErrorMessage'
import { PayoutHistoryContainer } from './styles'

export const Payouts = () => {
  const { data, hasNextPage, fetchNextPage, isFetching } =
    useSuspenseInfiniteQuery(disbursementsQueryOptions)
  const disbursementData = useMemo(
    () => (data?.pages || []).map(page => page.content).flat(),
    [data?.pages]
  )

  if (disbursementData.length === 0) return <NoPayouts />

  return (
    <Box direction="column" align="start">
      <Title size="xl" css={{ my: '$16' }}>
        Payouts
      </Title>
      <PayoutHistoryContainer direction="column">
        {disbursementData.map(disbursement => (
          <Payout
            payoutTotalAmountRequested={disbursement.amount}
            key={disbursement.id}
            id={disbursement.id}
            {...{
              ...disbursement.payout,
              ref: undefined, // Exclude the "ref" property from the spread as it's a reserved prop name in React
            }}
            description={disbursement.description}
            detailsRef={disbursement?.payout?.ref}
          />
        ))}
      </PayoutHistoryContainer>
      {hasNextPage && (
        <Box justify="center" fullWidth>
          <IconButton
            onClick={() => fetchNextPage()}
            mode="ghost"
            css={{ fontWeight: '$bold' }}
            iconRight
          >
            Load More
            {isFetching && <Loader size="sm" />}
          </IconButton>
        </Box>
      )}
    </Box>
  )
}

export const PayoutHistory = () => (
  <ErrorBoundary fallback={<PayoutErrorMessage />}>
    <Payouts />
  </ErrorBoundary>
)
