import { createRouter } from '@tanstack/react-router'

import { Loader } from '@/components/loader'
import { routeTree } from '@/routeTree.gen.ts'
import { LoginRequestBody } from '@/types/auth'

import { queryClient } from './query-client'

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
  interface HistoryState {
    track_id?: string
    loginRequestBody?: LoginRequestBody
  }
}

export const router = createRouter({
  context: {
    queryClient,
    auth: undefined!, // This will be set in the router root
  },
  routeTree,
  defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
  defaultPendingComponent: () => <Loader />,
})
