import { useNavigate } from '@tanstack/react-router'
import { FC } from 'react'

import { Button } from '@/components/button'
import { Box, Flex } from '@/components/layout'
import { SVGIcon } from '@/components/svgIcon'
import { Description, Title } from '@/components/typography'

export const PasswordResetSuccess: FC = () => {
  const navigate = useNavigate()
  const proceed = () => navigate({ to: '/login' })

  return (
    <Box direction="column">
      <Flex justify="center" css={{ mb: '$24' }}>
        <SVGIcon iconName="green_check" />
      </Flex>
      <Box direction="column" align="start">
        <Title>{`You're all set!`}</Title>
        <Description>
          Your password has been all set. Click continue to return to the log in
          screen.
        </Description>
      </Box>
      <Box css={{ mt: '$24', mr: 'auto' }}>
        <Button onClick={proceed}>Continue</Button>
      </Box>
    </Box>
  )
}
