import { yupResolver } from '@hookform/resolvers/yup'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Button } from '@/components/button'
import { Input } from '@/components/input'
import { Box } from '@/components/layout'
import { PhoneNumberInput } from '@/components/phone-input'
import { Description, Title, Typography } from '@/components/typography'
import { InputType } from '@/types/registration'

export type PhoneEmailValues = {
  phone?: string
  email?: string
}

const phoneEmailInitialValues = {
  phone: '',
  email: '',
}

const schema = yup.object().shape({
  phone: yup.string(),
  email: yup.string().email('Must be a valid email address'),
})

interface Props {
  onSubmit: (values: PhoneEmailValues) => void
}

export const PhoneEmailForm: FC<Props> = ({ onSubmit }) => {
  const [inputValue, setInputValue] = useState<string>('')
  const [inputType, setInputType] = useState<InputType>(InputType.EMAIL)

  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: phoneEmailInitialValues,
    resolver: yupResolver(schema),
  })

  const phoneNumber = watch('phone')

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setInputValue(value)
    setValue('email', value)
    if (value && value.length >= 3) {
      if (!isNaN(+value)) {
        setInputType(InputType.PHONE)
        setValue('email', '')
        setValue('phone', value)
        return
      } else {
        setValue('phone', '')
      }
    }
  }

  useEffect(() => {
    if (phoneNumber && phoneNumber.length <= 2) {
      setInputType(InputType.EMAIL)
      setInputValue('')
    }
  }, [phoneNumber])

  return (
    <Box direction="column" align="start">
      <Title>What’s your phone number or email address?</Title>
      <Description size="sm" css={{ mt: '$16', mb: '$40' }}>
        We’ll send you a secure link to set up your debit card for instant
        payments.
      </Description>
      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        direction="column"
        align="start"
      >
        {inputType === InputType.EMAIL && (
          <Box direction="column" align="start">
            <Input
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              placeholder="Enter phone number or email"
              type="email"
              {...register('email')}
              onChange={handleInputChange}
            />
            <Typography color="alert" size="xs">
              {errors?.email?.message}
            </Typography>
          </Box>
        )}
        {inputType === InputType.PHONE && (
          <Box direction="column" align="start">
            <PhoneNumberInput
              initialPhoneNumber={inputValue}
              onChange={value => setValue('phone', value)}
            />
            <Typography color="alert" size="xs">
              {errors?.phone?.message}
            </Typography>
          </Box>
        )}
        <Button type="submit" disabled={!inputValue} css={{ mt: '$40' }}>
          {inputValue ? 'Continue' : 'Enter Info'}
        </Button>
      </Box>
    </Box>
  )
}
