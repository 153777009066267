import { FC } from 'react'

import { ProgressIndicator, ProgressRoot } from './styles'

interface Props {
  progress: number
  indicatorColor?: string
}

export const ProgressBar: FC<Props> = ({
  progress,
  indicatorColor = '$successBackground',
}) => (
  <ProgressRoot value={progress}>
    <ProgressIndicator
      css={{
        transform: `translateX(-${100 - progress}%)`,
        backgroundColor: indicatorColor,
      }}
      data-testid="pw-strength-color"
    />
  </ProgressRoot>
)
