import { Box, Toast } from '@branch-messenger/willow-ui'
import { ToastProvider as Provider } from '@radix-ui/react-toast'
import React, { createContext, FC, ReactNode, useState } from 'react'

import { ShowToastProps, ToastContextType } from '@/types/toast'

export const ToastContext = createContext<ToastContextType>({
  showToast: () => null,
  hideToast: () => null,
})

const initialToastProps: ShowToastProps = {
  type: 'info',
  title: '',
  description: '',
}

interface Props {
  children: ReactNode
}

export const ToastProvider: FC<Props> = ({ children }) => {
  const [toastOpen, setToastOpen] = useState(false)
  const [toastProp, setToastProps] = useState<ShowToastProps>(initialToastProps)

  const showToast = (props: ShowToastProps) => {
    setToastProps(props)
    setToastOpen(true)
  }

  const hideToast = () => {
    setToastOpen(false)
    setToastProps(initialToastProps)
  }

  const toastContextValues = {
    showToast,
    hideToast,
  }

  return (
    <Provider>
      <ToastContext.Provider value={toastContextValues}>
        <Toast
          title={toastProp.title}
          description={<Box>{toastProp.description}</Box>}
          variant={toastProp.type}
          onOpenChange={hideToast}
          open={toastOpen}
        />
        {children}
      </ToastContext.Provider>
    </Provider>
  )
}

export const useToastContext = () => {
  const context = React.useContext(ToastContext)
  if (!context) {
    throw new Error('useToastContext must be used within a ToastProvider')
  }
  return context
}
