import { FC } from 'react'

import { CodeVerificationForm } from '@/components/verification/CodeVerificationForm'

interface Props {
  verifyCodePending: boolean
  requestCodePending: boolean
  confirmCode: (code: string) => void
  resendCode: () => void
  phoneLastFourDigits?: string
}

export const AccountUpdateCodeVerification: FC<Props> = ({
  confirmCode,
  resendCode,
  verifyCodePending,
  requestCodePending,
  phoneLastFourDigits,
}) => (
  <CodeVerificationForm
    onSubmit={confirmCode}
    onResendCode={resendCode}
    phoneLastFourDigits={phoneLastFourDigits || ''}
    verifyCodePending={verifyCodePending}
    requestCodePending={requestCodePending}
    verificationChannel="PHONE"
  />
)
