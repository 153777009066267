import * as PopoverPrimitive from '@radix-ui/react-popover'

import { keyframes, styled } from '@/styles/stitches.config'

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
})

export const PopoverContent = styled(PopoverPrimitive.Content, {
  borderRadius: '$md',
  zIndex: 1000,
  backgroundColor: 'white',
  padding: '$12',
  position: 'relative',
  '@mobile': {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  boxShadow: '$popover',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
})

export const PopoverArrow = styled(PopoverPrimitive.Arrow, {
  fill: 'white',
  height: 8,
  width: 15,
})

export const PopoverClose = styled(PopoverPrimitive.Close, {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '$lg',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'black',
  top: '$8',
  right: '$8',
  position: 'absolute',
  padding: '$4',
  '&:hover': {
    cursor: 'pointer',
    color: 'white',
    backgroundColor: '$darkGray',
  },
  '&:focus': { boxShadow: `$focus` },
})

export const PopoverAnchor = styled(PopoverPrimitive.Anchor, {
  width: '100%',
})

export const PopoverTrigger = PopoverPrimitive.Trigger

export const PopoverRoot = PopoverPrimitive.Root
export const PopoverPortal = PopoverPrimitive.Portal
