import { Box } from '@branch-messenger/willow-ui'
import { useMediaQuery } from '@uidotdev/usehooks'
import { FC } from 'react'

import BranchLogo from '@/assets/icons/branch_full.svg'

import { DesktopMenu } from './DesktopMenu'
import { MobileMenu } from './MobileMenu'
import { OrganizationSelector } from './OrganizationSelector'
import { OrgImage } from './styles'

type Props = {
  logoUrl?: string
  showOrgSelector?: boolean
  showLogout?: boolean
}

export const PageLayoutHeader: FC<Props> = ({
  logoUrl,
  showLogout = false,
  showOrgSelector = false,
}) => {
  const isTablet = useMediaQuery('only screen and (max-width : 769px)')
  const isMobile = useMediaQuery('only screen and (max-width : 480px)')

  return (
    <Box $fullWidth>
      <Box $display="flex" $justify="space-between" $gap={4} $py={4} $fullWidth>
        <Box $display="flex" $justify="space-between" $gap={4} $flex={1}>
          {logoUrl ? (
            <OrgImage src={logoUrl} alt="org_logo" />
          ) : (
            <Box>
              <BranchLogo height={35} width={'100%'} />
            </Box>
          )}
          {showOrgSelector && !isMobile && <OrganizationSelector />}
        </Box>
        {isTablet && <MobileMenu showLogout={showLogout} />}
        {!isTablet && <DesktopMenu showLogout={showLogout} />}
      </Box>
      {showOrgSelector && isMobile && <OrganizationSelector />}
    </Box>
  )
}
