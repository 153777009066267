import { useState } from 'react'

import { BookmarkPopup } from '@/components/bookmark-popup/bookmark-popup'
import { VerificationChannel } from '@/types/auth'

import { LoginCodeVerificationForm } from './LoginCodeVerificationForm'
import { LoginForm } from './LoginForm'

export type LoginData = {
  email: string
  password: string
  phoneLastFourDigits: string
}

export const Login = () => {
  const [loginData, setLoginData] = useState<LoginData | null>(null)
  const [verificationChannel, setVerificationChannel] =
    useState<VerificationChannel>('PHONE')

  return (
    <>
      <BookmarkPopup />
      {loginData ? (
        <LoginCodeVerificationForm
          loginData={loginData}
          verificationChannel={verificationChannel}
          setVerificationChannel={setVerificationChannel}
        />
      ) : (
        <LoginForm setLoginData={setLoginData} />
      )}
    </>
  )
}
