import { useSuspenseQuery } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { useContext } from 'react'
import { isMobile } from 'react-device-detect'

import { DialogContext } from '@/context'
import { formatAccountNumberLast4 } from '@/lib/string-formatting'
import {
  paymentProfileQueryOptions,
  useUpdatePaymentProfile,
} from '@/queries/profile'
import { useOrg } from '@/selectors/useOrg'
import { PaymentType } from '@/types/profile'

export interface AccountAction {
  iconName: string
  title: string
  description: string
  visible?: boolean
  handleClick?: () => void
}

export const useAccountActions = () => {
  const { data: paymentProfile } = useSuspenseQuery(paymentProfileQueryOptions)
  const updatePaymentProfile = useUpdatePaymentProfile()
  const { showAlert } = useContext(DialogContext)
  const navigate = useNavigate()
  const { orgName } = useOrg()

  const switchToCardPayment = () => {
    showAlert({
      title: 'Are you sure?',
      description: paymentProfile?.branch_direct_card_account
        ? `By switching your payout method to Debit Card ••• ${paymentProfile?.branch_direct_card_account.last4}, any new payouts will be deposited there.`
        : `Switching to a debit card payout does not close your Branch account. ` +
          `If you’d like to close your Branch account after switching to a card payment, contact Branch support via your Account tab.`,
      submitText: 'Yes, Continue',
      handleOnConfirm() {
        if (!paymentProfile?.branch_direct_card_account)
          return navigate({ to: '/debit-card' })
        updatePaymentProfile.mutate({
          active_type: PaymentType.CARD,
          from_type: paymentProfile?.active_type,
        })
      },
    })
  }

  const updateLinkedDebitCard = () => {
    paymentProfile?.branch_direct_card_account
      ? showAlert({
          title: 'Are you sure?',
          description:
            'Updating your linked debit card will delete your current debit card information from your account. ' +
            'If you’d like to add it back later, you’ll need to manually enter your card information again.',
          submitText: 'Yes, Continue',
          cancelText: 'No, cancel',
          handleOnConfirm() {
            navigate({ to: '/debit-card' })
          },
        })
      : navigate({ to: '/debit-card' })
  }

  const disableInstantPay = () =>
    showAlert({
      title: 'Are you sure?',
      description:
        'By removing your connected card, you are unenrolling from instant payouts through Branch. Please set up an alternative payment method through your organization.',
      submitText: 'Turn off',
      cancelText: 'No, Cancel',
      handleOnConfirm() {
        updatePaymentProfile.mutate(
          {
            active_type: PaymentType.NONE,
            from_type: paymentProfile?.active_type,
          },
          { onSuccess: () => navigate({ to: '/account/instant-pay-disabled' }) }
        )
      },
      isDestructive: true,
    })

  const switchToBranchAccount = () =>
    paymentProfile?.wallet
      ? showAlert({
          title: 'Are you sure?',
          description: `By switching your payout method to Branch Account ••• ${formatAccountNumberLast4(
            paymentProfile?.wallet?.account_number
          )}, any new payouts will be deposited there.`,
          submitText: 'Yes, Continue',
          handleOnConfirm() {
            updatePaymentProfile.mutate({
              active_type: PaymentType.WALLET,
              from_type: paymentProfile?.active_type,
            })
          },
        })
      : navigate({
          to: '/setup/additional-info',
        })

  const openBranchMobileApp = {
    iconName: 'external_link',
    title: `Open your Branch app`,
    description:
      'Manage income, pay bills, and save-all from your Branch wallet',
    visible: isMobile,
  }
  const switchToCard = {
    iconName: 'swap',
    title: 'Switch to debit card payouts',
    description: paymentProfile?.branch_direct_card_account
      ? `Account ending in ••• ${paymentProfile?.branch_direct_card_account?.last4}`
      : 'Link your debit card and receive payment instantly for just a small fee.',
    handleClick: switchToCardPayment,
  }

  const disableInstantPayment = {
    iconName: 'block',
    title: 'Turn off instant payouts',
    description: `You no longer want to receive instant payments from ${orgName}`,
    handleClick: disableInstantPay,
  }

  const updateLinkedCard = {
    iconName: 'pencil',
    title: `Update linked debit card`,
    description:
      'Link your debit card and receive payment instantly for just a small fee.',
    handleClick: updateLinkedDebitCard,
  }

  const switchToWallet = {
    iconName: 'swap',
    title: paymentProfile?.wallet
      ? 'Switch payouts to Branch'
      : 'Switch payouts to the Branch App',
    description: paymentProfile?.wallet
      ? `Account ••• ${formatAccountNumberLast4(
          paymentProfile?.wallet?.account_number
        )}`
      : 'Create a Branch account and get paid instantly without the fee.',
    handleClick: switchToBranchAccount,
  }

  const manageYourAccount = {
    iconName: 'circled_person',
    title: `Manage your account`,
    description: 'View and update your phone number or email.',
    handleClick: () => navigate({ to: '/account/management' }),
  }

  return {
    openBranchMobileApp,
    switchToCard,
    disableInstantPayment,
    updateLinkedCard,
    switchToWallet,
    manageYourAccount,
  }
}
