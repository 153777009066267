import { useSuspenseQuery } from '@tanstack/react-query'
import { FC, useEffect, useMemo, useRef } from 'react'

import { Box } from '@/components/layout'
import { Loader } from '@/components/loader'
import { parseData } from '@/lib/data-parser'
import {
  useCreateDebitCard,
  useDeleteDebitCard,
  useVerifyDebitCard,
} from '@/queries/card'
import { paymentProfileQueryOptions } from '@/queries/profile'
import { CreateDebitCardInfo } from '@/types/card'

import { CreateDebitCardContainer } from './styles'

interface Props {
  setStep: (step: number) => void
}

export const DebitCardForm: FC<Props> = ({ setStep }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const createDebitCard = useCreateDebitCard()
  const deleteDebitCard = useDeleteDebitCard()
  const paymentProfile = useSuspenseQuery(paymentProfileQueryOptions)
  const verifyCard = useVerifyDebitCard()

  const isPending = useMemo(
    () => createDebitCard.isPending || deleteDebitCard.isPending,
    [createDebitCard.isPending, deleteDebitCard.isPending]
  )
  const handleVerifyCard = (createCardRequestInfo: CreateDebitCardInfo) =>
    verifyCard.mutate(
      { requestBody: createCardRequestInfo },
      {
        onSuccess: () => {
          if (paymentProfile.data?.branch_direct_card_account) {
            handleUpdateDebitCard(
              paymentProfile.data.branch_direct_card_account.id,
              createCardRequestInfo
            )
          } else {
            handleCreateDebitCard(createCardRequestInfo)
          }
        },
      }
    )

  const handleCreateDebitCard = (createCardRequestInfo: CreateDebitCardInfo) =>
    createDebitCard.mutate(
      { requestBody: createCardRequestInfo },
      {
        onSuccess: () => setStep(2),
        onError: () => setStep(0),
      }
    )

  const handleUpdateDebitCard = (
    accountId: number,
    createCardRequestInfo: CreateDebitCardInfo
  ) =>
    deleteDebitCard.mutate(accountId, {
      onSuccess: () => handleCreateDebitCard(createCardRequestInfo),
    })

  useEffect(() => {
    const handleWindowMessage = (event: MessageEvent) => {
      if (event.source === iframeRef.current?.contentWindow) {
        if (event.data === 'Close') return
        const { createCardRequestInfo } = parseData(event.data)
        handleVerifyCard(createCardRequestInfo)
      }
    }

    window.addEventListener('message', handleWindowMessage)

    return () => {
      window.removeEventListener('message', handleWindowMessage)
    }
  }, [])

  if (isPending) {
    return (
      <Box direction="column" justify="center" align="center">
        <Loader size="lg" />
      </Box>
    )
  }

  return (
    <CreateDebitCardContainer>
      <iframe
        src={import.meta.env.VITE_TABAPAY_IFRAME_URL}
        title="Debit Account Form"
        ref={iframeRef}
      />
    </CreateDebitCardContainer>
  )
}
