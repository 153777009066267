import * as Primitive from '@radix-ui/react-checkbox'
import type { VariantProps } from '@stitches/react'

import { styled } from '@/styles/stitches.config'

export const CheckboxRoot = styled(Primitive.Root, {
  backgroundColor: 'white',
  dimensions: '$18',
  br: '$sm',
  display: 'flex',
  padding: 0,
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px solid $gray',
  '-webkit-appearance': 'none',
  '&:hover:not([data-disabled]), &[data-state="checked"]': {
    backgroundColor: '$successBackground',
    border: '2px solid $successBackground',
  },

  variants: {
    mode: {
      circular: {
        borderRadius: '100%',
      },
    },
    size: {
      large: {
        dimensions: '$24',
      },
    },
  },
})

export const CheckboxIndicator = styled(Primitive.Indicator, {
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
})

export const Label = styled('label', {
  pl: '$12',
  lineHeight: 1,
})

export type CheckboxVariants = VariantProps<typeof CheckboxRoot>
