import { styled } from '@/styles/stitches.config'

export const Badge = styled('span', {
  br: '$lg',
  color: 'white',
  fontWeight: '$bold',
  padding: '$8 $16 $8 $16',
  fontSize: '$12',
  variants: {
    type: {
      success: {
        color: 'black',
        backgroundColor: '$successBackground',
      },
      danger: {
        backgroundColor: '$alert',
      },
      secondary: {
        backgroundColor: '$selection',
      },
    },
  },
  defaultVariants: {
    type: 'secondary',
  },
})
