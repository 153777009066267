import { CSS } from '@stitches/react'
import { FC, ReactNode } from 'react'

import { Typography } from '@/components/typography'

import {
  DetailButtonContainer,
  DetailButtonContentContainer,
  DetailButtonIconContainer,
  DetailButtonText,
} from './styles'

interface Props {
  title: string
  handleOnClick: () => void
  description?: string
  icon?: ReactNode
  css?: CSS
  disabled?: boolean
  active?: boolean
}

export const DetailButton: FC<Props> = ({
  css,
  icon,
  title,
  description,
  disabled,
  active,
  handleOnClick,
}) => (
  <DetailButtonContainer
    active={active}
    disabled={disabled}
    css={css}
    onClick={handleOnClick}
  >
    {icon && <DetailButtonIconContainer>{icon}</DetailButtonIconContainer>}
    <DetailButtonContentContainer direction="column">
      <DetailButtonText direction="column" align="start">
        <Typography bold size="md">
          {title}
        </Typography>
        <Typography
          size="xs"
          css={{ wordWrap: 'break-word', textAlign: 'left', mt: '$4' }}
        >
          {description}
        </Typography>
      </DetailButtonText>
    </DetailButtonContentContainer>
  </DetailButtonContainer>
)
