import { Box } from '@branch-messenger/willow-ui'
import { FC, PropsWithChildren } from 'react'

import defaultPartnerGraphic from '@/assets/images/fallback_partner_graphic.png'

import {
  PageLayoutContentChildren,
  PageLayoutItem,
  PartnerLogoContainer,
  StyledPageLayoutContent,
} from './styles'

export const PageLayoutContent: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box $flexGrow={1} $display="flex" $align="flex-start" $fullWidth>
      <StyledPageLayoutContent>
        <PageLayoutItem direction="column">
          <PageLayoutContentChildren direction="column" justify="center">
            {children}
          </PageLayoutContentChildren>
        </PageLayoutItem>
        <PageLayoutItem
          hideOnSmallerScreen
          justify="center"
          css={{ padding: '0 2.5% 0 5%' }}
        >
          <PartnerLogoContainer>
            <img
              src={defaultPartnerGraphic}
              alt="partner_graphic"
              height="90%"
            />
          </PartnerLogoContainer>
        </PageLayoutItem>
      </StyledPageLayoutContent>
    </Box>
  )
}
