import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { AxiosError, AxiosResponse } from 'axios'
import { useContext } from 'react'

import {
  createAccount,
  getTerms,
  sendDeepLink,
  updateAccountEmail,
  updateAccountEmailOTP,
  updateAccountPhone,
  updateAccountPhoneDeeplink,
  updateAccountPhoneOTP,
  updateAccountPhoneVerifyEmail,
  verifyUser,
} from '@/api/registration'
import { DialogContext } from '@/context'
import { useAuth } from '@/context/Auth'
import { handleHttpException } from '@/lib/httpException'
import { sendAnalyticsEvent, setUserProperties } from '@/lib/mixpanel'
import { OldIResponse } from '@/types/common'
import {
  CreateAccountRequestBody,
  DeepLinkRequestBody,
  UpdateUserEmailBody,
  UpdateUserPhoneBody,
  VerifyUserQueryParams,
} from '@/types/registration'

export const accountNeedsSetupError =
  'Employee email or phone number is missing'
export const useSendDeepLink = () => {
  const { orgId } = useAuth()
  const { showAlert } = useContext(DialogContext)

  return useMutation<
    AxiosResponse,
    AxiosError<OldIResponse>,
    DeepLinkRequestBody
  >({
    mutationFn: async (data: DeepLinkRequestBody) => {
      const res = await sendDeepLink(orgId, data)
      return res.data
    },
    onError: error => {
      const defaultErrorMessage =
        'An unknown error occurred. Please try again later.'
      handleHttpException<OldIResponse>(error, {
        onHttpError: error => {
          let errorMessage = error.response?.data.message || defaultErrorMessage
          if (errorMessage === accountNeedsSetupError) {
            errorMessage =
              'Org setup has not been completed. Please contact your payroll administrator to proceed with sign up.'
            showAlert({
              title: 'Setup Incomplete',
              description: errorMessage,
              hideCancel: true,
              handleOnConfirm: () => null,
            })
          }
          sendAnalyticsEvent('AUTH', 'sendDeepLink:failure', { errorMessage })
        },
        onOtherError: () => {
          sendAnalyticsEvent('AUTH', 'sendDeepLink:failure', {
            errorMessage: defaultErrorMessage,
          })
        },
      })
    },
  })
}

export const useVerifyUser = () => {
  const { orgId } = useAuth()
  const navigate = useNavigate()
  const { showAlert } = useContext(DialogContext)

  return useMutation({
    mutationFn: async (params: VerifyUserQueryParams) => {
      const { track_id, password } = params
      const res = await verifyUser(orgId, { track_id, password })
      return res.data
    },

    onSuccess: (data, params) => {
      setUserProperties({ email: data?.email })
      sendAnalyticsEvent('ONBOARDING', 'verification:success')
      navigate({
        to: '/setup/password-creation/$email',
        params: { email: data?.email },
        state: state => ({
          ...state,
          track_id: params.track_id,
        }),
      })
    },

    onError: (_, params) => {
      sendAnalyticsEvent('ONBOARDING', 'verification:failure')
      showAlert({
        title: params.org_passcode_required
          ? 'Incorrect Passcode'
          : `Invalid last 4.`,
        description: params.org_passcode_required
          ? 'Please check your spelling or reach out to your manager for the correct passcode.'
          : `Please check your last 4 and try again.`,
        hideCancel: true,
        handleOnConfirm: () => null,
      })
    },
  })
}

export const useCreateAccount = () => {
  const { orgId } = useAuth()
  const { showAlert } = useContext(DialogContext)
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (data: CreateAccountRequestBody) => {
      const res = await createAccount(orgId, data)
      return res
    },

    onSuccess: (response, payload) => {
      sendAnalyticsEvent('ONBOARDING', 'create-account:success')

      if (response.status === 201) {
        return navigate({
          to: '/setup/email-verification/$email',
          params: { email: payload.email },
          state: {
            loginRequestBody: {
              email: payload.email,
              password: payload.password,
            },
          },
        })
      }

      navigate({
        to: '/debit-card',
      })
    },

    onError: () => {
      sendAnalyticsEvent('ONBOARDING', 'create-account:failure')
      showAlert({
        title: `Account creation failed.`,
        description: `There was an error when creating your account. Please contact support if the issue persists.`,
        hideCancel: true,
        handleOnConfirm: () => null,
      })
    },
  })
}

const GET_TERMS_QUERY = 'get-terms-query'
export const useTerms = () => {
  return useQuery({
    queryKey: [GET_TERMS_QUERY],
    queryFn: async () => {
      const res = await getTerms()
      return res.data
    },
  })
}

export const useUpdateAccountEmailOTP = () => {
  const { showAlert } = useContext(DialogContext)

  return useMutation({
    mutationFn: async () => {
      const res = await updateAccountEmailOTP()
      return res
    },

    onSuccess: () => {
      sendAnalyticsEvent('UPDATE', 'email-otp:success')
    },

    onError: () => {
      sendAnalyticsEvent('UPDATE', 'email-otp:failure')
      showAlert({
        title: `Invalid email address.`,
        description: `Please check your email address and try again.`,
        hideCancel: true,
        handleOnConfirm: () => null,
      })
    },
  })
}

export const useUpdateAccountEmail = () => {
  const { showAlert } = useContext(DialogContext)

  return useMutation({
    mutationFn: async (data: UpdateUserEmailBody) => {
      const res = await updateAccountEmail(data)
      return res
    },

    onSuccess: () => {
      sendAnalyticsEvent('UPDATE', 'email:success')
    },

    onError: () => {
      sendAnalyticsEvent('UPDATE', 'email:failure')
      showAlert({
        title: `Invalid code.`,
        description: `Please try again or request a new code by tapping resend code.`,
        hideCancel: true,
        handleOnConfirm: () => null,
      })
    },
  })
}

export const useUpdateAccountPhoneDeeplink = () => {
  const { showAlert } = useContext(DialogContext)

  return useMutation({
    mutationFn: async () => {
      const res = await updateAccountPhoneDeeplink()
      return res
    },

    onSuccess: () => {
      sendAnalyticsEvent('UPDATE', 'phone-deeplink:success')
    },

    onError: () => {
      sendAnalyticsEvent('UPDATE', 'phone-deeplink:failure')
      showAlert({
        title: `Invalid phone number.`,
        description: `Please check your phone number and try again.`,
        hideCancel: true,
        handleOnConfirm: () => null,
      })
    },
  })
}

const GET_PHONE_UPDATE_EMAIL_VERIFICATION =
  'get-phone-update-email-verification-query'
export const usePhoneUpdateEmailVerification = () => {
  return useQuery({
    queryKey: [GET_PHONE_UPDATE_EMAIL_VERIFICATION],
    queryFn: async () => {
      const res = await updateAccountPhoneVerifyEmail()
      return res.data
    },
  })
}

export const useUpdateAccountPhoneOTP = () => {
  const { showAlert } = useContext(DialogContext)

  return useMutation({
    mutationFn: async (phone: string) => {
      const res = await updateAccountPhoneOTP(phone)
      return res
    },

    onSuccess: () => {
      sendAnalyticsEvent('UPDATE', 'phone-otp:success')
    },

    onError: (error: unknown) => {
      sendAnalyticsEvent('UPDATE', 'phone-otp:failure')
      const errorMessage = (error as AxiosError<OldIResponse>)?.response?.data
        .message
      if (errorMessage === 'Email verification not completed') return
      showAlert({
        title: `Invalid phone number.`,
        description: `Please check your phone number and try again.`,
        hideCancel: true,
        handleOnConfirm: () => null,
      })
    },
  })
}

export const useUpdateAccountPhone = () => {
  const { showAlert } = useContext(DialogContext)
  const navigate = useNavigate()

  return useMutation({
    mutationFn: async (data: UpdateUserPhoneBody) => {
      const res = await updateAccountPhone(data)
      return res
    },

    onSuccess: () => {
      sendAnalyticsEvent('UPDATE', 'phone:success')
      showAlert({
        title: `Phone Update Success.`,
        description: `Successfully updated your phone number.`,
        hideCancel: true,
        handleOnConfirm: () =>
          navigate({
            to: '/account',
          }),
      })
    },

    onError: () => {
      sendAnalyticsEvent('UPDATE', 'phone:failure')
      showAlert({
        title: `Invalid code.`,
        description: `Please try again or request a new code by tapping resend code.`,
        hideCancel: true,
        handleOnConfirm: () => null,
      })
    },
  })
}
