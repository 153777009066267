import { yupResolver } from '@hookform/resolvers/yup'
import { useParams, useRouterState } from '@tanstack/react-router'
import { FC, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { IconButton } from '@/components/button'
import { Input } from '@/components/input'
import { PasswordInput } from '@/components/input/PasswordInput'
import { Box, FlexGrid } from '@/components/layout'
import { Loader } from '@/components/loader'
import { PasswordStrengthPopOver } from '@/components/password'
import { Description, Title, Typography } from '@/components/typography'
import { isValidPassword } from '@/lib/passwordStrength'
import { useCreateAccount, useTerms } from '@/queries/registration'

export type CreateAccountFormValues = {
  email: string
  password: string
  confirm_password: string
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Must be a valid email address')
    .required('Email is required'),
  password: yup
    .string()
    .required('Password is required')
    .test('password-strength', 'Password is not valid', value => {
      const { isValid } = isValidPassword(value)
      return isValid
    }),
  confirm_password: yup
    .string()
    .required('Confirm Password is required')
    .test('password-strength', 'Passwords do not match', (value, context) => {
      return value === context.parent.password
    }),
})
export const PasswordCreation: FC = () => {
  const { data: terms } = useTerms()
  const track_id = useRouterState({
    select: ({ location }) => location.state.track_id,
  })
  const createAccount = useCreateAccount()
  const { email } = useParams({ from: '/setup/password-creation/$email' })

  const loginFormInitialValues: CreateAccountFormValues = useMemo(
    () => ({
      email,
      password: '',
      confirm_password: '',
    }),
    [email]
  )

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { isValid, errors },
    setValue,
  } = useForm<CreateAccountFormValues>({
    defaultValues: loginFormInitialValues,
    resolver: yupResolver(schema),
  })

  const onSubmit = async (values: CreateAccountFormValues) => {
    track_id &&
      createAccount.mutate({
        ...values,
        track_id: track_id,
        terms: (terms || []).map(({ name, version }) => ({ name, version })),
      })
  }

  return (
    <Box direction="column" align="start">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title>Setup your account.</Title>
        <Description size="sm" css={{ mt: '$16', mb: '$40' }}>
          {`After your account is set up, you'll have the flexibility to update
          your instant payout method at any time.`}
        </Description>
        <FlexGrid
          direction="column"
          align="start"
          size="lg"
          css={{
            width: 384,
            '@mobile': {
              width: '100%',
            },
          }}
        >
          <Box direction="column" align="start">
            <Typography color="hint" size="sm">
              EMAIL ADDRESS
            </Typography>
            <Input
              {...register('email')}
              type="email"
              placeholder="Email Address"
            />
          </Box>
          <PasswordStrengthPopOver
            trigger={
              <Box direction="column" align="start">
                <Typography color="hint" size="sm">
                  PASSWORD
                </Typography>
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <PasswordInput
                      onChange={onChange}
                      value={value}
                      onBlur={onBlur}
                    />
                  )}
                />
                <Typography color="alert" size="sm">
                  {errors.password?.message}
                </Typography>
              </Box>
            }
            password={watch('password')}
          />
          <Box direction="column" align="start">
            <Typography color="hint" size="sm">
              CONFIRM PASSWORD
            </Typography>
            <Controller
              name="confirm_password"
              control={control}
              render={({ field: { value, onBlur } }) => (
                <PasswordInput
                  onChange={e => {
                    setValue('confirm_password', e.target.value, {
                      shouldValidate: true,
                    })
                  }}
                  value={value}
                  onBlur={onBlur}
                />
              )}
            />
            <Typography color="alert" size="sm">
              {errors.confirm_password?.message}
            </Typography>
          </Box>
          <IconButton
            css={{ margin: '$16 0 $40 0', mr: 'auto' }}
            type="submit"
            iconRight
            disabled={!isValid || createAccount.isPending}
          >
            <Typography>{isValid ? 'Continue' : 'Enter details'}</Typography>
            {createAccount.isPending && <Loader />}
          </IconButton>
        </FlexGrid>
      </form>
    </Box>
  )
}
