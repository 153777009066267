import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from '@tanstack/react-router'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Button, IconButton } from '@/components/button'
import { Input } from '@/components/input'
import { PasswordInput } from '@/components/input/PasswordInput'
import { Box } from '@/components/layout'
import { Loader } from '@/components/loader'
import { Description, Title, Typography } from '@/components/typography'
import { useLogin } from '@/queries/auth'

import { LoginData } from './Login'

interface Props {
  setLoginData: (data: LoginData) => void
}

export type LoginFormValues = {
  email: string
  password: string
}

const loginFormInitialValues: LoginFormValues = {
  email: '',
  password: '',
}

const schema = yup.object().shape({
  email: yup.string().email('Must be a valid email address').required(),
  password: yup.string().required('Password is required'),
})

export const LoginForm: FC<Props> = ({ setLoginData }) => {
  const loginRequest = useLogin()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    defaultValues: loginFormInitialValues,
    resolver: yupResolver(schema),
  })

  const login = (values: LoginFormValues) => {
    loginRequest.mutate(values, {
      onSuccess: res =>
        setLoginData({
          email: values.email,
          password: values.password,
          phoneLastFourDigits: res.phone_last_four_digits,
        }),
    })
  }

  const resetPassword = () =>
    navigate({
      to: '/request-password-reset',
    })

  return (
    <Box direction="column" align="start">
      <form onSubmit={handleSubmit(login)} style={{ width: '100%' }}>
        <Title>Welcome Back</Title>
        <Description size="sm" css={{ mt: '$16', mb: '$40' }}>
          Login to your account and take control of how you’re paid.
        </Description>
        <Box direction="column">
          <Input
            {...register('email')}
            type="email"
            placeholder="Email Address"
            css={{ mb: '$12' }}
          />
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value, onBlur } }) => (
              <PasswordInput
                onChange={onChange}
                value={value}
                onBlur={onBlur}
              />
            )}
          />
          <Box
            direction="column"
            justify="between"
            align="start"
            css={{ mt: '$24' }}
          >
            <Typography size="sm" color="muted">
              Forgot password?
            </Typography>
            <Button
              type="button"
              mode="link"
              css={{ mt: '$4' }}
              onClick={resetPassword}
            >
              Reset {`>`}
            </Button>
          </Box>
          <IconButton
            css={{ margin: '$16 0 $40 0', mr: 'auto' }}
            type="submit"
            iconRight
            disabled={!isValid || loginRequest.isPending}
          >
            {isValid ? 'Login' : 'Enter Info'}
            {loginRequest.isPending && <Loader />}
          </IconButton>
        </Box>
      </form>
    </Box>
  )
}
