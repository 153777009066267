import { useEffect, useState } from 'react'

// This hook is used to create a timer that counts down from the given minutes and seconds.
// without dependent on a ref element.
export const useSimpleTimer = (minutes: number, seconds: number) => {
  const initialMinutes = minutes
  const initialSeconds = seconds
  const [isCompleted, setIsCompleted] = useState(false)
  const [currentMinutes, setCurrentMinutes] = useState(minutes)
  const [currentSeconds, setCurrentSeconds] = useState(seconds)

  useEffect(() => {
    const tick = () => {
      if (currentMinutes === 0 && currentSeconds === 0) {
        setIsCompleted(true)
        return
      }

      if (currentSeconds === 0) {
        setCurrentMinutes(prevMinutes => prevMinutes - 1)
        setCurrentSeconds(59)
      } else {
        setCurrentSeconds(prevSeconds => prevSeconds - 1)
      }
    }

    const timerId = setInterval(tick, 1000)

    return () => clearInterval(timerId)
  }, [currentMinutes, currentSeconds])

  const resetCountDown = () => {
    setIsCompleted(false)
    setCurrentMinutes(initialMinutes)
    setCurrentSeconds(initialSeconds)
  }

  return {
    resetCountDown,
    isCompleted,
    currentMinutes,
    currentSeconds,
  }
}
