import { DropdownMenuItem } from '@branch-messenger/willow-ui'
import { Box } from '@branch-messenger/willow-ui'
import styledComponents from 'styled-components'

import { Flex } from '@/components/layout'
import { styled } from '@/styles/stitches.config'

export const StyledPageLayoutContainer = styled(Flex, {
  width: '80vw',
  margin: 'auto',
  minHeight: '100svh',

  '@tablet': {
    width: '95vw',
    margin: '0 auto',
  },
  defaultVariants: {
    direction: 'column',
  },
})

export const PageLoadingContainer = styled(Flex, {
  height: '100dvh',
  defaultVariants: {
    justify: 'center',
    align: 'center',
  },
})

export const OrgImage = styledComponents.img`
  height: 35px
`

export const StyledPageLayoutFooter = styledComponents(Box).attrs({
  $fullWidth: true,
  $display: 'flex',
  $justify: 'space-between',
})`
height: 50px;
`

export const StyledPageLayoutContent = styled(Flex, {
  width: '100%',
  minHeight: '621px',
  margin: '64px 0',
})

export const PageLayoutContentChildren = styled(Flex, {
  width: '100%',
  maxWidth: '100%',
  height: '100%',
})

export const PageLayoutContentContainer = styled(Flex, {
  width: '100%',
  maxWidth: '100%',
  overflowY: 'auto',
})

export const PageLayoutItem = styled(Flex, {
  width: '100%',
  height: '100%',

  variants: {
    hideOnSmallerScreen: {
      true: {
        '@tablet': {
          display: 'none',
        },
      },
    },
  },
})

export const PartnerLogoContainer = styledComponents(Box).attrs({
  $display: 'flex',
  $justify: 'center',
})`
  width: 100%;
  height: 100%;
  max-width: 400px;
  border-radius: 32px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  };
`

export const OrgDropdownImage = styledComponents.img`
  width: 100%;
`

export const OrgDropdownImageContainer = styledComponents.div`
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.radii.rounded};
  border: 1px solid ${({ theme }) => theme.colors.gray};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space[1]};
`

export const StyledHelpLink = styledComponents.a`
  text-decoration: none;
`

export const StyledDropdownMenuItem = styledComponents(DropdownMenuItem)`
  padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[2]};
`
