import { ComponentProps, FC, useEffect, useRef, useState } from 'react'

export const useDynamicSvgImport = (svgName: string) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<unknown>()
  const importRef = useRef<FC<ComponentProps<'svg'>>>()

  useEffect(() => {
    setLoading(true)
    setError(undefined)

    const importSvgIcon = async () => {
      try {
        importRef.current = (
          await import(`../assets/icons/${svgName}.svg`)
        ).default
      } catch (err) {
        setError(err as Error)
      } finally {
        setLoading(false)
      }
    }

    importSvgIcon()
  }, [svgName])

  return { loading, error, SvgIcon: importRef.current }
}
