import { Box, Dropdown, Icon, IconButton } from '@branch-messenger/willow-ui'
import {
  LogOut01,
  Menu01,
  MessageQuestionCircle,
  XClose,
} from '@branch-messenger/willow-ui/icons'
import { FC, useState } from 'react'

import { useLogout } from '@/queries/auth'

import { StyledDropdownMenuItem, StyledHelpLink } from './styles'

type Props = {
  showLogout?: boolean
}

export const MobileMenu: FC<Props> = ({ showLogout }) => {
  const [open, setOpen] = useState(false)
  const logout = useLogout()
  const handleLogout = () => logout.mutate()

  return (
    <Dropdown
      rootProps={{ open, onOpenChange: setOpen }}
      trigger={
        <IconButton>
          <Icon Icon={open ? XClose : Menu01} size={4} />
        </IconButton>
      }
      showArrow={false}
    >
      <StyledDropdownMenuItem asChild>
        <StyledHelpLink
          href="https://support.branchapp.com/hc/en-us/sections/20572256284315-Branch-Direct"
          target="_blank"
          rel="noreferrer"
        >
          <Box $display="flex" $gap={2}>
            <Icon Icon={MessageQuestionCircle} />
            Support
          </Box>
        </StyledHelpLink>
      </StyledDropdownMenuItem>
      {showLogout && (
        <StyledDropdownMenuItem onSelect={handleLogout}>
          <Box $display="flex" $gap={2}>
            <Icon Icon={LogOut01} />
            Log out
          </Box>
        </StyledDropdownMenuItem>
      )}
    </Dropdown>
  )
}
