import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useContext } from 'react'

import { createDebitCard, deleteDebitCard, verifyDebitCard } from '@/api/card'
import { DialogContext } from '@/context'
import { sendAnalyticsEvent } from '@/lib/mixpanel'
import { CreateDebitCardInfo, VerifyCardErrorReasonCode } from '@/types/card'

import { GET_PAYMENT_PROFILE_QUERY } from './profile'

export const useCreateDebitCard = () => {
  const { showAlert } = useContext(DialogContext)
  const queryCache = useQueryClient()
  return useMutation({
    mutationFn: async ({
      requestBody,
    }: {
      requestBody: CreateDebitCardInfo
    }) => {
      const { data } = await createDebitCard(requestBody)
      return data
    },

    onSuccess: () => {
      sendAnalyticsEvent('ACCOUNT', 'card:create:success')
      queryCache.invalidateQueries({
        queryKey: [GET_PAYMENT_PROFILE_QUERY],
      })
    },

    onError: (err: AxiosError<{ message: string; reason_code: string }>) => {
      sendAnalyticsEvent('ACCOUNT', 'card:create:failure')
      const description = (err?.response?.data?.reason_code as string)
        ? err?.response?.data?.reason_code
        : 'We are unable to submit your request at this time. Please try again in a few minutes.'
      showAlert({
        title: 'Unable to submit',
        description,
        cancelText: 'OK',
      })
    },
  })
}

export const useDeleteDebitCard = () => {
  const { showAlert } = useContext(DialogContext)
  const mutation = useMutation({
    mutationFn: async (entityId: number) => {
      const { data } = await deleteDebitCard(entityId)
      return data
    },

    onSuccess: () => sendAnalyticsEvent('ACCOUNT', 'card:delete:success'),

    onError: (_, params) => {
      sendAnalyticsEvent('ACCOUNT', 'card:delete:failure')
      showAlert({
        title: 'Error deleting card',
        description:
          'Something went wrong deleting your card. Please try again.',
        cancelText: 'No, cancel',
        submitText: 'Try again',
        handleOnConfirm: () => {
          mutation.mutate(params)
        },
      })
    },
  })
  return mutation
}

const generateVerifyCardErrorMessage = (
  reasonCode: VerifyCardErrorReasonCode
) => {
  switch (reasonCode) {
    case VerifyCardErrorReasonCode.UNSUPPORTED_CARD_TYPE:
      return {
        title: 'Unable to verify card',
        description: `Please double-check that the card information is accurate and that it's either a Visa or Mastercard.`,
      }
    case VerifyCardErrorReasonCode.NOT_DEBIT_CARD:
      return {
        title: 'Unsupported card type.',
        description:
          'The connected card must be a debit card. Credit cards cannot be linked. Please enter a different card.',
      }
    case VerifyCardErrorReasonCode.INVALID_CARD_DETAILS:
      return {
        title: 'Invalid card details',
        description:
          'The card details you entered are invalid. Please try again.',
      }
    default:
      return {
        title: 'Submission Error',
        description:
          'We are unable to submit your request at this time. Please try again in a few minutes.',
      }
  }
}

export const useVerifyDebitCard = () => {
  const { showAlert } = useContext(DialogContext)
  return useMutation({
    mutationFn: async ({
      requestBody,
    }: {
      requestBody: CreateDebitCardInfo
    }) => {
      const { data } = await verifyDebitCard(requestBody)
      return data
    },

    onError: (err: AxiosError<{ message: string; reason_code: string }>) => {
      const { title, description } = generateVerifyCardErrorMessage(
        err?.response?.data?.reason_code as VerifyCardErrorReasonCode
      )
      showAlert({
        title: title,
        description: description,
        cancelText: 'OK',
      })
    },
  })
}
