import { Cross2Icon } from '@radix-ui/react-icons'
import { FC, PropsWithChildren, ReactElement } from 'react'

import { Box } from '../layout'
import {
  PopoverAnchor,
  PopoverArrow,
  PopoverClose,
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
} from './styles'
import { PopoverPosition } from './types'

// NOTE: popover portal will interfere if Popover is used inside a modal, so pass withPortalComponent={false} to disable it

interface Props {
  trigger: ReactElement
  position?: PopoverPosition
  showClose?: boolean
  open: boolean
  setOpen: (open: boolean) => void
  withPortalComponent?: boolean
  disableAutoFocus?: boolean
}

interface ContentContainerProps {
  withPortalComponent: boolean
}

const ContentContainer: FC<PropsWithChildren<ContentContainerProps>> = ({
  withPortalComponent,
  children,
}) => {
  if (!withPortalComponent) {
    return <>{children}</>
  }
  return <PopoverPortal>{children}</PopoverPortal>
}

export const Popover: FC<PropsWithChildren<Props>> = ({
  children,
  trigger,
  position = 'bottom',
  showClose,
  open,
  setOpen,
  withPortalComponent = true,
  disableAutoFocus = false,
}) => {
  const openPopover = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setOpen(true)
  }
  const closePopover = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    setOpen(false)
  }
  return (
    <PopoverRoot open={open}>
      <PopoverAnchor>
        <PopoverTrigger asChild onClick={openPopover}>
          {trigger}
        </PopoverTrigger>
      </PopoverAnchor>
      <ContentContainer withPortalComponent={withPortalComponent}>
        <PopoverContent
          onOpenAutoFocus={e => disableAutoFocus && e.preventDefault()}
          side={position}
          onPointerDownOutside={() => setOpen(false)}
        >
          <Box direction="column">{children}</Box>
          <PopoverArrow />
          {showClose && (
            <PopoverClose onClick={closePopover}>
              <Cross2Icon />
            </PopoverClose>
          )}
        </PopoverContent>
      </ContentContainer>
    </PopoverRoot>
  )
}
