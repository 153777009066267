import { useSuspenseQuery } from '@tanstack/react-query'
import { useSearch } from '@tanstack/react-router'
import { FC, useState } from 'react'

import { Box } from '@/components/layout'
import { resetPasswordTrackIDQueryOptions } from '@/queries/reset-password'

import { PasswordResetSuccess } from './PasswordResetSuccess'
import { RequestPasswordCodeVerification } from './ResetPasswordCodeVerification'
import { ResetPasswordForm } from './ResetPasswordForm'

const FormSteps: FC = () => {
  const [step, setStep] = useState(0)
  const [OTP, setOTP] = useState('')

  const { token } = useSearch({
    from: '/registration/password/exchange',
  })

  const {
    data: { track_id },
  } = useSuspenseQuery(resetPasswordTrackIDQueryOptions(token))

  switch (step) {
    case 0:
      return (
        <RequestPasswordCodeVerification
          setStep={setStep}
          setOTP={setOTP}
          track_id={track_id}
        />
      )
    case 1:
      return (
        <ResetPasswordForm setStep={setStep} OTP={OTP} track_id={track_id} />
      )
    case 2:
      return <PasswordResetSuccess />
    default:
      return (
        <RequestPasswordCodeVerification
          setStep={setStep}
          setOTP={setOTP}
          track_id={track_id}
        />
      )
  }
}

export const ResetPassword = () => (
  <Box direction="column" align="start">
    <FormSteps />
  </Box>
)
