import { Box, Button } from '@branch-messenger/willow-ui'
import { FC } from 'react'

import { useLogout } from '@/queries/auth'

import { SVGIcon } from '../svgIcon'

type Props = {
  showLogout?: boolean
}

export const DesktopMenu: FC<Props> = ({ showLogout }) => {
  const logout = useLogout()
  const handleLogout = () => logout.mutate()
  return (
    <Box $display="flex" $gap={4}>
      <a
        href="https://support.branchapp.com/hc/en-us/sections/20572256284315-Branch-Direct"
        target="_blank"
        rel="noreferrer"
      >
        <SVGIcon
          iconName="circle_question_mark"
          wrapperStyle={{ display: 'flex', cursor: 'pointer' }}
        />
      </a>
      {showLogout && (
        <Button onClick={handleLogout} variant="link" color="secondary">
          Log Out
        </Button>
      )}
    </Box>
  )
}
