import { FC } from 'react'

import { CodeVerificationForm } from '@/components/verification/CodeVerificationForm'
import {
  useLoginUserMutation,
  useResendCode,
  useVerifyCode,
} from '@/queries/auth'
import { VerificationChannel } from '@/types/auth'

import { LoginData } from './Login'

interface Props {
  loginData: LoginData
  verificationChannel: VerificationChannel
  setVerificationChannel?: (channel: VerificationChannel) => void
}

export const LoginCodeVerificationForm: FC<Props> = ({
  loginData,
  verificationChannel,
  setVerificationChannel = () => null,
}) => {
  const resendOtp = useResendCode()
  const verifyCode = useVerifyCode()
  const loginUser = useLoginUserMutation()

  const resendCode = (channel: VerificationChannel) => {
    resendOtp.mutate({
      ...loginData,
      verification_channel: channel,
    })
  }

  const confirmCode = (code: string) => {
    verifyCode.mutate(
      {
        email: loginData.email,
        otp: code,
        verification_channel: verificationChannel,
      },
      {
        onSuccess: () => loginUser.mutate(),
      }
    )
  }

  return (
    <CodeVerificationForm
      onSubmit={confirmCode}
      onResendCode={resendCode}
      phoneLastFourDigits={loginData.phoneLastFourDigits}
      email={loginData.email}
      verificationChannel={verificationChannel}
      verifyCodePending={verifyCode.isPending}
      requestCodePending={resendOtp.isPending}
      setVerificationChannel={setVerificationChannel}
    />
  )
}
