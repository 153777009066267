import { Box, Typography } from '@branch-messenger/willow-ui'

import BranchIcon from '@/assets/icons/branch_full.svg'

import { StyledPageLayoutFooter } from './styles'

export const PageLayoutFooter = () => {
  return (
    <StyledPageLayoutFooter>
      <Box
        $align="flex-start"
        $direction="column"
        $justify="center"
        style={{ width: 'auto' }}
      >
        <Typography $size="xs" color="muted">
          All rights reserved.
        </Typography>
        <Typography $size="xs" color="muted">
          Copyright © {new Date().getFullYear()} Branch.
        </Typography>
      </Box>
      <Box $direction="column">
        <Typography $size="xs" color="muted">
          Powered by
        </Typography>
        <Box>
          <BranchIcon />
        </Box>
      </Box>
    </StyledPageLayoutFooter>
  )
}
