import { Box, Button, Input, Typography } from '@branch-messenger/willow-ui'
import { ChangeEvent, FC, useState } from 'react'

import { Loader } from '@/components/loader'
import { Description, Title } from '@/components/typography'
import { VerificationCodeGetHelp } from '@/containers/Login/VerificationCodeGetHelp'
import { VerificationChannel } from '@/types/auth'

interface Props {
  onSubmit: (code: string) => void
  onResendCode: (channel: VerificationChannel) => void
  phoneLastFourDigits: string
  verificationChannel: VerificationChannel
  requestCodePending: boolean
  verifyCodePending: boolean
  setVerificationChannel?: (channel: VerificationChannel) => void
  email?: string
}

export const CodeVerificationForm: FC<Props> = ({
  onSubmit,
  onResendCode,
  phoneLastFourDigits,
  requestCodePending,
  verifyCodePending,
  setVerificationChannel,
  verificationChannel,
  email,
}) => {
  const [code, setCode] = useState('')

  const handleCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (!value) return setCode('')
    setCode(value)
  }

  const method = verificationChannel === 'PHONE' ? 'texted' : 'emailed'

  const description =
    verificationChannel === 'PHONE' ? (
      <>
        Please enter the code texted to your phone number ending in{' '}
        <strong>{phoneLastFourDigits}</strong>.
      </>
    ) : (
      <>
        Please enter the code sent to <strong>{email}</strong>.
      </>
    )

  return (
    <Box $direction="column" $align="start">
      <Title>Enter the code we {method} you.</Title>
      {phoneLastFourDigits && (
        <Description size="sm" css={{ mt: '$16' }}>
          {description}
        </Description>
      )}

      {requestCodePending ? (
        <Loader />
      ) : (
        <Box $direction="column" $align="start" $mt={10}>
          <Input
            name="confirmationCode"
            type="text"
            placeholder="Enter Code"
            onChange={handleCodeChange}
            disabled={verifyCodePending}
          />
          <Box $mt={1}>
            <Typography $size="sm" $color="textMuted">
              Standard messaging rates may apply.
            </Typography>
          </Box>
        </Box>
      )}
      {setVerificationChannel && (
        <VerificationCodeGetHelp
          setVerificationChannel={setVerificationChannel}
          resendCode={onResendCode}
          phoneLastFourDigits={phoneLastFourDigits}
          verificationChannel={verificationChannel}
          email={email || ''}
        />
      )}
      <Box $mt={6}>
        <Button
          onClick={() => onSubmit(code)}
          disabled={!code || requestCodePending || verifyCodePending}
        >
          Enter Code
        </Button>
      </Box>
    </Box>
  )
}
