import { queryOptions, useMutation } from '@tanstack/react-query'
import { useContext } from 'react'

import {
  getResetPasswordTrackID,
  requestResetPassword,
  resetPassword,
  sendResetPasswordOTP,
} from '@/api/reset-password'
import { DialogContext } from '@/context'
import { sendAnalyticsEvent } from '@/lib/mixpanel'
import {
  RequestResetPasswordRequestBody,
  ResetPasswordRequestBody,
} from '@/types/reset-password'

export const useRequestResetPassword = () => {
  return useMutation({
    mutationFn: async (data: RequestResetPasswordRequestBody) => {
      const res = await requestResetPassword(data)
      return res.data
    },

    onSuccess: () => {
      sendAnalyticsEvent('RESET-PASSWORD', 'request-reset:success')
    },

    onError: () => {
      sendAnalyticsEvent('RESET-PASSWORD', 'request-reset:failure')
    },
  })
}

export const SEND_RESET_PASSWORD_OTP_QUERY = 'send-reset-password-otp-query'

export const resetPasswordOTPQueryOptions = (track_id: string) => {
  return queryOptions({
    queryKey: [SEND_RESET_PASSWORD_OTP_QUERY, track_id],

    queryFn: async () => {
      const res = await sendResetPasswordOTP({ track_id: track_id })
      return res.data
    },

    enabled: !!track_id,
  })
}

export const GET_PASSWORD_RESET_TRACK_ID_QUERY =
  'get-password-reset-track-id-query'

export const resetPasswordTrackIDQueryOptions = (token: string) =>
  queryOptions({
    queryKey: [GET_PASSWORD_RESET_TRACK_ID_QUERY],
    queryFn: async () => {
      const res = await getResetPasswordTrackID(token)
      return res.data
    },
    staleTime: Infinity,
    gcTime: Infinity,
  })

export const useResetPassword = () => {
  const { showAlert } = useContext(DialogContext)
  return useMutation({
    mutationFn: async (data: ResetPasswordRequestBody) => {
      const res = await resetPassword(data)
      return res.data
    },

    onSuccess: () => {
      sendAnalyticsEvent('RESET-PASSWORD', 'reset-password:success')
    },

    onError: () => {
      sendAnalyticsEvent('RESET-PASSWORD', 'reset-password:failure')
      showAlert({
        title: `Failed to reset password.`,
        description: `Please contact support if the issue persists.`,
        hideCancel: true,
      })
    },
  })
}
