import {
  CreateAccountRequestBody,
  DeepLinkRequestBody,
  GetTrackIDResponse,
  TermsDetails,
  UpdateUserEmailBody,
  UpdateUserPhoneBody,
  VerifyUserRequestBody,
  VerifyUserResponse,
} from '@/types/registration'

import { branchHttp } from './branch-http'
import { http } from './http'

/**
 * Verifies if a user can sign up for Branch-Direct.
 * Sends a magic link after verification.
 * @param data Contains email or phone number
 */
export const sendDeepLink = (orgId: string, data: DeepLinkRequestBody) =>
  http.post(`registration/organizations/${orgId}/deep-link`, data)

/**
 * This end-point is used to exchange a temporary token with a permanent track id.
 * The temporary token gets invalidated after retrieving the track id.
 * @param token exhange token to be use to get track id
 */
export const getTrackID = (orgId: string, token: string) =>
  http.get<GetTrackIDResponse>(
    `registration/organizations/${orgId}/exchange?token=${token}`
  )

/**
 * Verifies user using the provided ssn.
 * Verifies the Track ID per signup request.
 * @param data Contains ssn last four and track id
 */
export const verifyUser = (orgId: string, data: VerifyUserRequestBody) =>
  http.post<VerifyUserResponse>(
    `registration/organizations/${orgId}/verify`,
    data
  )

/**
 * Verifies the validity of the email address, password strength, and the tracking ID before creating an entry in the database.
 * Returns a token after registration.
 * @param data Contains user credentials and track id
 */
export const createAccount = (orgId: string, data: CreateAccountRequestBody) =>
  http.post(`registration/organizations/${orgId}/signup`, data)

/**
 * Send OTP for user email address update
 */
export const updateAccountEmailOTP = () => http.post(`registration/email/otp`)

/**
 * Email address update
 */
export const updateAccountEmail = (data: UpdateUserEmailBody) =>
  http.put(`registration/email`, data)

/**
 * Send Email Deeplink for user phone update
 */
export const updateAccountPhoneDeeplink = () =>
  http.post(`registration/phone/deep-link`)

/**
 * Verify Email Deeplink for user phone update
 */
export const updateAccountPhoneVerifyEmail = () =>
  http.get(`verification/email/update-phone`)

/**
 * Send OTP for user phone update
 */
export const updateAccountPhoneOTP = (phone: string) =>
  http.post(`registration/phone/otp`, { phone })

/**
 * Phone update
 */
export const updateAccountPhone = (data: UpdateUserPhoneBody) =>
  http.put(`registration/phone`, data)

/**
 * Retrieves the terms and conditions that the user must accept prior to creating an account.
 */
export const getTerms = () =>
  branchHttp.get<TermsDetails[]>('p2/accounts/terms/direct/pre_user')
