import { Box, Button } from '@branch-messenger/willow-ui'
import styled, { css, keyframes } from 'styled-components'

export const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(282px);
    
  } 
  to {
    opacity: 1;
    transform: translateY(0rem);
  }
`

export const slideDown = keyframes`
  from {
    opacity: 1;
    transform: translateY(0rem);
    
  } 
  to {
    opacity: 0;
    transform: translateY(282px);
  }
`
type BookmarkPopupProps = {
  $isMobile?: boolean
  $show?: boolean
}

export const BookmarkPopupContainer = styled(Box)<BookmarkPopupProps>`
  position: fixed;
  display: flex;
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 10%;
  border: 1px solid ${props => props.theme.colors.gray};
  border-radius: ${props => props.theme.radii.lg};
  width: 385px;
  height: 282px;
  background-color: ${props => props.theme.colors.surface};
  z-index: ${props => props.theme.zIndex.toast};
  transition: visibility 1000ms linear;

  ${({ $show }) => {
    if ($show)
      return css`
        animation: ${slideUp} 1000ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
      `
    return css`
      animation: ${slideDown} 1000ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
    `
  }}

  ${({ $isMobile }) => {
    return (
      $isMobile &&
      css`
        height: 102px;
        width: 375px;
        bottom: 5%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
      `
    )
  }}
`

export const BookmarkPopupClose = styled('div')<{ $isMobile?: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10%;
  right: 10%;
  ${({ $isMobile }) => {
    return (
      $isMobile &&
      css`
        top: 10%;
        right: 2.5%;
      `
    )
  }}
`

export const BookmarkPopupCloseButton = styled(Button)<{ $isMobile?: boolean }>`
  border-radius: ${props => props.theme.radii.rounded};
  height: 32px;
  width: 32px;
  ${({ $isMobile }) => {
    return (
      $isMobile &&
      css`
        height: 18px;
        width: 18px;
        padding: 10px;
        opacity: 0.5;
      `
    )
  }}
`

export const BookmarkPopupContent = styled(Box)<{ $isMobile?: boolean }>`
  display: flex;
  padding: ${props => props.theme.space[8]};
  margin-bottom: ${props => props.theme.space[4]};
  text-align: center;
  ${({ theme, $isMobile }) => {
    return (
      $isMobile &&
      css`
        text-align: start;
        padding: ${theme.space[4]};
        margin-bottom: 0;
      `
    )
  }}
`
